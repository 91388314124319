import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { BASEURL } from "../../constants/constants";
import Project from "./Project";

const HomeComponent = ({ saveProject }) => {
  const [projectList, setProjectList] = useState([]);
  const [cookie] = useCookies(["token"]);

  const axiosClient = axios.create({
    baseURL: BASEURL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${cookie.token.accessToken}`,
    },
  });

  const fetchProjectList = (projectUsersFiltered) => {
    console.log("Filtered projectUsers is right?", projectUsersFiltered);
    axiosClient
      .get(`/projects/`)
      .then((res) => {
        console.log("GET project list: ", res);
        const filteredProjects = res.data.filter(({ id }) => {
          return projectUsersFiltered.some(
            (projectUser) => projectUser.project_id === id
          );
        });
        console.log("Filtered project List:", filteredProjects);
        setProjectList(filteredProjects);
      })
      .catch((err) => {
        console.log("Project got catch Err: ", err);
      });
  };

  const fetchProjectUserList = async () => {
    try {
      const res = await axiosClient.get(`/project_users/`);
      console.log("Get project users:", res.data);
      const userID = Number(cookie.user_id);
      const filteredUsersByUserID = res.data.filter((projectUser) => {
        return projectUser.res_id === userID;
      });
      console.log("Filtered project users:", filteredUsersByUserID);
      return filteredUsersByUserID;
    } catch (err) {
      console.log("Err project users: ", err);
    }
  };

  const getProjects = async () => {
    const projectUsersFiltered = await fetchProjectUserList();
    fetchProjectList(projectUsersFiltered);
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">ID</TableCell>
            <TableCell align="center">案件名</TableCell>
            <TableCell align="center">更新日</TableCell>
            <TableCell align="center">選択</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projectList.map((project) => (
            <Project
              project={project}
              saveProject={() => saveProject}
              key={project.id}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HomeComponent;
