import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Route, Routes } from "react-router-dom";
import "./App.scss";
import DeliverlyManager from "./components/DM/DeliverlyManager";
import Header from "./components/Header";
import Login from "./components/Login/Login";
import Home from "./components/RES/Home";
import ProjectDetail from "./components/RES/ProjectDetail";
import { BASEURL } from "./constants/constants";

const App = () => {
  const [userID, setUserID] = useState(0);
  const [project, setProject] = useState({});
  const [isLoggined, setIsLoggined] = useState(false);
  const [cookie, setCookie, removeCookie] = useCookies([
    "token",
    "user_authority",
    "user_id",
  ]);
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 650,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    typography: {
      button: {
        textTransform: "none",
      },
    },
  });

  const axiosClient = axios.create({
    baseURL: BASEURL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  const getRefresh = () => {
    console.log(cookie.token);
    axiosClient
      .post("token/refresh/", { refresh: cookie.token.refreshToken })
      .then((res) => {
        const token = {
          accessToken: res.data.access,
          refreshToken: res.data.refresh,
        };
        setCookie("token", token, { path: "/" });
        setIsLoggined(true);
      })
      .catch((err) => {
        console.log("Err!");
        console.log(err);
        removeCookie("token");
        setIsLoggined(false);
        console.log("refresh token is valid");
      });
  };

  const verifyToken = () => {
    if (cookie.token && !isLoggined) {
      axiosClient
        .post("/token/verify/", {
          token: cookie.token.accessToken,
        })
        .then((res) => {
          console.log("Get Verify", res);
          setIsLoggined(true);
          console.log("true");
        })
        .catch((err) => {
          if (err.response.status === 401 && cookie.token.refreshToken) {
            console.log("You should Refresh");
            getRefresh();
          } else {
            setIsLoggined(false);
            console.log("Cannot refresh in App", err);
          }
          console.log(err);
        });
    }
  };

  useEffect(() => {
    verifyToken();
  }, []);

  const saveProject = (newProject) => {
    setProject({ newProject });
    console.log("save project");
    console.log(newProject);
  };

  const logout = () => {
    removeCookie("token");
    removeCookie("user_id");
    removeCookie("user_authority");
    setIsLoggined(false);
  };

  const AuthorityProvider = () => {
    console.log("Authority: ", cookie.user_authority);
    if (cookie.user_authority === "1") {
      return <Home saveProject={(p) => saveProject(p)} />;
    } else if (cookie.user_authority === "2") {
      return <DeliverlyManager />;
    } else {
      console.log("Err: Authority not found");
      return <>banana</>;
    }
  };

  return (
    <div className="container">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header
          user_id={cookie.user_id}
          isLoggined={isLoggined}
          logout={() => logout()}
        />
        {isLoggined ? (
          <Routes>
            <Route path="/" element={<AuthorityProvider />} />
            <Route
              path="/project/:id"
              element={
                <ProjectDetail
                  project={project}
                  user_id={userID}
                  isLoggined={isLoggined}
                />
              }
            />
          </Routes>
        ) : (
          <Login
            isLoggined={isLoggined}
            setIsLoggined={setIsLoggined}
            setUserID={setUserID}
          />
        )}
      </ThemeProvider>
    </div>
  );
};

export default App;
