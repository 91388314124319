import { Box, Container, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { BASEURL } from "../../constants/constants";

const LoginComponent = ({ setIsLoggined, isLoggined }) => {
  const [userList, setUserList] = useState([]);
  const [value, setValue] = useState();
  const [password, setPassword] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [cookie, setCookie] = useCookies(["token"]);

  useEffect(() => {
    console.log(userList[0]);
    const fetchUserList = async () => {
      await axios
        .get(BASEURL + "/user_mails/")
        .then((res) => {
          setUserList(res.data);
          console.log("user_mails", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchUserList();
  }, []);

  const emailChange = (e) => {
    setValue(e.target.value);
  };

  const passwordChange = (e) => {
    setPassword(e.target.value);
  };

  const fetchAuthority = async (user_id, accessToken) => {
    await axios
      .get(BASEURL + `/user/${user_id}/`, {
        headers: {
          Authorization: `JWT ${accessToken}`,
        },
      })
      .then((res) => {
        console.log("Get authority:", res);
        setCookie("user_authority", res.data.authority, { path: "/" });
        // setCookie("user_authority", 1, { path: "/" });
        setIsLoggined(true);
      })
      .catch((err) => {
        console.log("Err authority:", err);
      });
  };

  const fetchLogin = async (accessPass) => {
    await axios
      .post(BASEURL + "/token/", accessPass)
      .then((res) => {
        console.log(res);
        const token = {
          accessToken: res.data.access,
          refreshToken: res.data.refresh,
        };
        console.log("SetCookie: LOGIN");
        setCookie("token", token, { path: "/" });
        setCookie("user_id", res.data.user_id, { path: "/" });
        fetchAuthority(res.data.user_id, res.data.access);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setErrorMsg("ログイン情報が間違っています。");
        } else {
          setErrorMsg(err.message);
        }
      });
  };

  const getLogin = () => {
    const loginElem = {
      email: value,
      password: password,
    };
    fetchLogin(loginElem);
  };

  return (
    <>
      <Container component="login" maxWidth="xs">
        <p>{errorMsg}</p>
        <Box
          sx={{
            marginTop: 12,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextField
            margin="normal"
            fullWidth
            required
            id="email"
            type="email"
            value={value}
            label="E-mail"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => emailChange(e)}
          />
          <TextField
            margin="normal"
            fullWidth
            required
            autoComplete="password"
            name="password"
            label="Password"
            id="password"
            type="password"
            value={password}
            onChange={(e) => passwordChange(e)}
          />
        </Box>
        <Button
          sx={{ marginTop: 4 }}
          variant="contained"
          onClick={() => getLogin()}
        >
          ログイン
        </Button>
      </Container>
    </>
  );
};

export default LoginComponent;
