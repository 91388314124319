import { Box, InputLabel, TextField } from "@mui/material";
import React, { useContext } from "react";
import {
  GetServerDataContext,
  SetStatusContext,
  StatusContext,
} from "./ProjectDetail";

const AnswerEviComponent = ({ proposition }) => {
  const serverData = useContext(GetServerDataContext);
  const status = useContext(StatusContext);
  const setStatus = useContext(SetStatusContext);

  const childText = serverData.children["ChildText"].filter((child) => {
    return (
      child.propositions_id === proposition.id &&
      child.sources_id === serverData.sources[serverData.source_index].id
    );
  });

  const setEvi = (propID, evi) => {
    setStatus({
      ...status,
      answerInt: {
        ...status.answerInt,
        [propID]: { ...status.answerInt[propID], evidence: evi },
      },
    });
  };

  const setMes = (propID, mes) => {
    setStatus({
      ...status,
      answerInt: {
        ...status.answerInt,
        [propID]: { ...status.answerInt[propID], message: mes },
      },
    });
  };

  const evidenceChange = (e) => {
    setEvi(proposition.id, e.target.value);
  };

  const messageChange = (e) => {
    setMes(proposition.id, e.target.value);
  };

  return (
    <Box className="evidence__container">
      <div className="evidence">
        <InputLabel className="evi_label" htmlFor={proposition.id + "evi"}>
          根拠
        </InputLabel>
        <TextField
          className="evi_text"
          error={status.Err[proposition.id]?.evidence}
          helperText={status.Err[proposition.id]?.evidence?.message}
          id={proposition.id + "evi"}
          type="text"
          multiline={true}
          maxRows={10}
          value={
            status.answerInt[proposition.id] &&
            status.answerInt[proposition.id].evidence
          }
          placeholder={childText[0] && childText[0].evidence}
          onChange={(e) => evidenceChange(e)}
        />
      </div>

      <div className="message">
        <InputLabel className="evi_label" htmlFor={proposition.id + "mes"}>
          申送
        </InputLabel>
        <TextField
          className="evi_text"
          error={status.Err[proposition.id]?.message}
          helperText={status.Err[proposition.id]?.message?.message}
          id={proposition.id + "mes"}
          type="text"
          value={
            status.answerInt[proposition.id] &&
            status.answerInt[proposition.id].message
          }
          onChange={(e) => messageChange(e)}
          placeholder={childText[0] && childText[0].message}
        />
      </div>
    </Box>
  );
};

export default AnswerEviComponent;
