import axios from "axios";

export const apiClient = axios.create({
	
	baseURL: "http://localhost:8000/api",
	headers: {
		'Content-Type': 'application/json',
	}
})

export const ApiClientProvider = ({children}) => {
	// const [cookie, setCookie] = useCookies(['token']);
	// const navigate = useNavigate();
	
	// const fetchRefreshToken = async (refreshToken) => {
	// 	await axios.post('http://localhost:8000/api/token/refresh/', refreshToken).then((res) => {
	// 		setCookie("accessToken", res.data.accessToken);
	// 	}).catch((err) => {
	// 		navigate("/login");
	// 	})
	// }

	// apiClient.interceptors.request.use((config) => {
	// 	if(config.headers !== undefined) {
	// 		if(cookie.token.accessToken) {
	// 		}
	// 	}
	// 	console.log(cookie.token.accessToken)
	// 	config.headers.Authorization = `JWT ${cookie.token.accessToken}`;
	// 	console.log(config)

	// return config
	// })

	// apiClient.interceptors.response.use(
	// 	(response) => {
	// 		return response
	// 	},
	// 	(error) => {
	// 		switch (error.response?.status) {
	// 		case 401:
	// 			// cookie.token.refreshToken && fetchRefreshToken({refresh: cookie.token.refreshToken})
	// 			break
	// 		default:
	// 			break
	// 		}
	// 	return Promise.reject(error)
	// 	}
	// )
	// useEffect(() => {
	//   const requestInterceptors = apiClient.interceptors.request.use((config) => {
	// 	if(config.headers !== undefined) {
	// 		if(cookie.token.accessToken) {
	// 			config.headers.Authorization = `JWT ${cookie.token.accessToken}`;
	// 		}
	// 	}

	// 	return config
	//   })

	//   const responseInterceptor = apiClient.interceptors.response.use(
	// 	(response) => {
	// 	  return response
	// 	},
	// 	(error) => {
	// 	  switch (error.response?.status) {
	// 		case 401:
	// 			cookie.token.refreshToken && fetchRefreshToken(cookie.token.refreshToken)
	// 			break
	// 		default:
	// 		  break
	// 	  }
	// 	  return Promise.reject(error)
	// 	 }
	//    )
	
	//   return () => {
	// 	apiClient.interceptors.request.eject(requestInterceptors)
	// 	apiClient.interceptors.response.eject(responseInterceptor)
	//   }
	// }, [])
	

	return (<>{children}</>)
}
