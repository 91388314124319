import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { SnackbarProvider } from "notistack";
import React from "react";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ApiClientProvider } from "./API/apiClient";
import App from "./App";
import SnackbarCloseButton from "./components/DM/SnackbarCloseButton";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <BrowserRouter>
        <ApiClientProvider>
          <SnackbarProvider
            iconVariant={{
              success: "✅",
              error: "❗　",
              warning: "⚠️",
              info: "ℹ️",
            }}
            maxSnack={5}
            action={(snackbarKey) => (
              <SnackbarCloseButton snackbarKey={snackbarKey} />
            )}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <App />
          </SnackbarProvider>
        </ApiClientProvider>
      </BrowserRouter>
    </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
