import { TextField } from "@mui/material";
import React, { useContext } from "react";
import {
  GetServerDataContext,
  SetStatusContext,
  StatusContext,
} from "./ProjectDetail";

const InputPlainComponent = ({ proposition }) => {
  const serverData = useContext(GetServerDataContext);
  const status = useContext(StatusContext);
  const setStatus = useContext(SetStatusContext);

  const childText = serverData.children["ChildText"].filter((child) => {
    return (
      child.propositions_id === proposition.id &&
      child.sources_id === serverData.sources[serverData.source_index].id
    );
  });

  const handleChange = (e) => {
    setStatus({
      ...status,
      answerStr: {
        ...status.answerStr,
        [proposition.id]: {
          ...status.answerStr[proposition.id],
          value: e.target.value,
        },
      },
    });
  };

  return (
    <TextField
      className="input_text"
      variant="standard"
      error={status.Err[proposition.id]?.value}
      helperText={status.Err[proposition.id]?.value?.message}
      color="primary"
      id={proposition.id}
      type="text"
      value={
        status.answerStr[proposition.id] &&
        status.answerStr[proposition.id].value
      }
      onChange={(e) => handleChange(e)}
      placeholder={childText[0] ? childText[0].value : "回答を入力"}
    />
  );
};

export default InputPlainComponent;
