import ReplayIcon from "@mui/icons-material/Replay";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { saveAs } from "file-saver";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { BASEURL, MODE_INDEX } from "../../constants/constants";
import Classification from "./Classification";
import Projects from "./Projects";
import ResAssignModal from "./ResAssignModal";
import ResManagement from "./ResManagement";
import Title from "./Title";

const DeliverlyManager = ({}) => {
  const [cookie, setCookie, removeCookie] = useCookies(["token"]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [resAssignModalOpen, setResAssignModalOpen] = useState(false);
  const [isGettingProjects, setIsGettingProjects] = useState(false);
  const [dmMode, setDmMode] = useState(MODE_INDEX["main"]);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [isDetailLoading, setIsDetailLoading] = useState(false);
  const [isCreateProjectOpen, setIsCreateProjectOpen] = useState(false);
  const longListRef = useRef();
  const [countURL, setCountURL] = useState(0);
  const [countDoneSource, setCountDoneSource] = useState(0);
  const [createProjectTitle, setCreateProjectTitle] = useState("");
  const [userList, setUserList] = useState([
    {
      id: 0,
      name: "",
      email: "",
      authority: 0,
    },
  ]);
  const [projectList, setProjectList] = useState([
    {
      id: 0,
      name: "",
      create_date: "",
      update_date: "",
    },
  ]);
  const [project, setProject] = useState({
    id: 0,
    name: "",
    create_date: "",
    update_date: "",
  });

  const axiosClient = axios.create({
    baseURL: BASEURL,
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + cookie.token.accessToken,
    },
  });

  const fetchProjects = async () => {
    try {
      const res = await axiosClient.get("/projects/");
      console.log("Get Projects:", res);
      setProjectList(res.data);
      setIsGettingProjects(true);
    } catch (err) {
      console.log("Err getting Projects:", err);
    }
  };
  const fetchUserList = async () => {
    try {
      const res = await axiosClient.get("/users/1");
      console.log("Get UserList:", res.data);
      setUserList(res.data);
    } catch (err) {
      console.log("Err getting UserList:", err);
    }
  };

  const uploadCSV = async (CSVdata) => {
    axiosClient
      .post(
        `/sources/uploadSourcesCSV/${project.id}/${CSVdata.name}/`,
        CSVdata,
        {
          "content-type": "multipart/form-data",
        }
      )
      .then((res) => {
        console.log("Upload CSV:", res);
      })
      .catch((err) => {
        console.log("Err uploading CSV:", err);
      });
  };

  const fetchCounts = async () => {
    try {
      const res = await axiosClient.get(
        `/sourceUsers/${project.id}/finishedEntry/`
      );
      console.log("Get finished counts:", res.data);
      const urls = res.data.URLcount;
      const done = res.data.done;
      let sourceProgress = 0;
      if (urls !== 0) {
        sourceProgress = (done / urls) * 100;
      }
      setCountURL(urls);
      setCountDoneSource(sourceProgress);
    } catch (err) {
      console.log("Err getting counts:", err);
    }
  };

  const handleCloseDetail = () => {
    setIsDetailOpen(false);
  };

  const handleClickReloadLonglist = async () => {
    const CSVdata = await longListRef.current.files[0];
    if (!CSVdata) return;
    console.log(longListRef.current.files);

    uploadCSV(CSVdata);
  };

  const handleClickReload = async (e) => {
    console.log("reload ...");
    setIsDetailLoading(true);
    await fetchCounts();
    setIsDetailLoading(false);
  };

  const getDownloadExcel = () => {
    axiosClient
      .get(`project/resultDownload/${project.id}/`, {
        responseType: "blob",
        dataType: "binary",
      })
      .then((res) => {
        console.log(res);
        const data = new Blob([res.data]);
        saveAs(data, project.name + ".xlsx");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClickDeleteProject = () => {
    const confirm = window.confirm(
      "本当に削除してよろしいですか？この操作は取り消せません。"
    );
    if (confirm) {
      axiosClient
        .delete(`/projects/${project.id}`)
        .then((res) => {
          console.log(res);
          fetchProjects();
          handleCloseDetail();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleClickExcel = () => {
    getDownloadExcel();
  };

  const handleChangeCreateProjectTitle = (e) => {
    setCreateProjectTitle(e.target.value);
  };

  const handleCloseCreateProject = () => {
    setIsCreateProjectOpen(false);
    setCreateProjectTitle("");
  };

  const handleClickCreateProject = () => {
    if (createProjectTitle !== "") {
      axiosClient
        .post(`/projects/`, { name: createProjectTitle })
        .then((res) => {
          console.log(res);
          fetchProjects();
          handleCloseCreateProject();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      enqueueSnackbar("案件名が空白です。", {
        variant: "warning",
        autoHideDuration: 10000,
      });
    }
  };

  useEffect(() => {
    fetchUserList();
    fetchProjects();
  }, []);

  useEffect(() => {
    fetchCounts();

    return () => {
      setCountURL(0);
      setCountDoneSource(0);
    };
  }, [isDetailOpen]);

  return (
    <>
      <Title
        dmMode={dmMode}
        setDmMode={setDmMode}
        setIsCreateProjectOpen={setIsCreateProjectOpen}
      />
      {dmMode === MODE_INDEX["main"] ? (
        <>
          <Projects
            projectList={projectList}
            setProject={setProject}
            setResAssignModalOpen={setResAssignModalOpen}
            resAssignModalOpen={resAssignModalOpen}
            setDmMode={setDmMode}
            isGettingProjects={isGettingProjects}
            setIsDetailOpen={setIsDetailOpen}
          />
          <Dialog
            open={isCreateProjectOpen}
            onClose={handleCloseCreateProject}
            className="createProject_dialog"
          >
            <DialogTitle className="title">
              <Typography>新規案件作成</Typography>
            </DialogTitle>
            <DialogContent className="content">
              <InputLabel>案件名</InputLabel>
              <TextField
                value={createProjectTitle}
                onChange={handleChangeCreateProjectTitle}
              />
            </DialogContent>
            <DialogActions className="actions">
              <Button color="inherit" onClick={handleCloseCreateProject}>
                キャンセル
              </Button>
              <Button onClick={handleClickCreateProject}>作成</Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={isDetailOpen}
            onClose={handleCloseDetail}
            maxWidth={"xl"}
          >
            <Backdrop open={isDetailLoading} sx={{ zIndex: 50 }}>
              <CircularProgress sx={{ color: "#f2f2f2" }} />
            </Backdrop>
            <DialogTitle className="detail_dialogtitle">
              <Typography>案件詳細・ロングリスト登録</Typography>
              <ReplayIcon
                className="reload__button"
                onClick={handleClickReload}
              />
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Box className="detail__container">
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Box className="title">
                        <Typography>案件名</Typography>
                      </Box>
                      <Box className="longlist">
                        <Typography>ロングリスト</Typography>
                      </Box>
                      <Box className="url">
                        <Typography>URL</Typography>
                      </Box>
                      <Box className="progress">
                        <Typography>進捗</Typography>
                      </Box>
                      <Box className="disagree">
                        <Typography>不一致件数</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={9}>
                      <Box className="title">
                        <Typography>{project.name}</Typography>
                      </Box>
                      <Box className="longlist">
                        <input
                          className="longlist__button"
                          ref={longListRef}
                          type="file"
                        />
                        <Button
                          className="longlist__reload"
                          variant="contained"
                          onClick={handleClickReloadLonglist}
                        >
                          送信
                        </Button>
                      </Box>
                      <Box className="url">
                        {countURL === 0 ? (
                          <Typography>{`-- 件`}</Typography>
                        ) : (
                          <Typography>{`${countURL}件`}</Typography>
                        )}
                      </Box>
                      <Box className="progress">
                        <Typography>{`${countDoneSource}%`}</Typography>
                      </Box>
                      <Box className="disagree">
                        <Typography>（開発中）</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Button
                    className="excel_button"
                    variant="outlined"
                    onClick={handleClickExcel}
                  >
                    Excelダウンロード
                  </Button>
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions className="actions">
              <Button color="warning" onClick={handleClickDeleteProject}>
                案件削除
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : dmMode === MODE_INDEX["res"] ? (
        <ResManagement />
      ) : dmMode === MODE_INDEX["classy"] ? (
        <Classification project={project} axiosClient={axiosClient} />
      ) : null}
      <ResAssignModal
        key={project.id}
        axiosClient={axiosClient}
        setResAssignModalOpen={(bool) => setResAssignModalOpen(bool)}
        resAssignModalOpen={resAssignModalOpen}
        project={project}
        userList={userList}
      />
    </>
  );
};

export default DeliverlyManager;
