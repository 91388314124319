// const BASEURL = "http://127.0.0.1:8000/api"; //local
const BASEURL = "https://xs020974.xsrv.jp/kinaco/api"; //xserver

const ASSIGN_MAX_COUNT = 2;

const MODE_INDEX = {
  main: 1,
  detail: 2,
  edit: 3,
  classy: 4,
  res: 5,
};

const USER_LIST = [
  "NONE",
  "KING",
  "HAYAO",
  "NUMA",
  "MASA",
  "NAOKI",
  "KENTO",
  "EISUKE",
  "ZIZI",
  "MIDORI",
  "TOTTIE",
  "GON",
  "MORI",
  "YAKU",
];

export { BASEURL, USER_LIST, ASSIGN_MAX_COUNT, MODE_INDEX };
