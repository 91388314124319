import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";

const ResAssignModal = ({
  resAssignModalOpen,
  setResAssignModalOpen,
  project,
  userList,
  axiosClient,
}) => {
  const [resKwargByEmail, setResKwargByEmail] = useState("");
  const [emailListByUser, setEmailListByUser] = useState([""]);
  const [searchedUserIndex, setSearchedUserIndex] = useState(-1);
  const [assignedResAry, setAssignedResAry] = useState([0]);
  const [assignedResObj, setAssignedResObj] = useState([
    {
      id: 0,
      name: "",
      email: "",
      authority: 0,
    },
  ]);
  const [notAssignedResObj, setNotAssignedResObj] = useState([
    {
      id: 0,
      name: "",
      email: "",
      authority: 0,
    },
  ]);

  const handleClose = () => {
    setResAssignModalOpen(false);
  };

  const handleAppend = (user) => {
    if (!assignedResObj.includes(user)) {
      setAssignedResObj((prev) => [...prev, user]);
    }
    if (notAssignedResObj.includes(user)) {
      setNotAssignedResObj((prev) =>
        prev.filter((prev_user) => prev_user !== user)
      );
    }
  };

  const handleDelete = (user) => {
    if (assignedResObj.includes(user)) {
      setAssignedResObj((prev) =>
        prev.filter((prev_user) => prev_user !== user)
      );
    }
    if (!notAssignedResObj.includes(user)) {
      setNotAssignedResObj((prev) => [...prev, user]);
    }
  };

  const handleApeendAll = () => {
    setAssignedResObj(userList);
    setNotAssignedResObj([]);
  };

  const handleDeleteAll = () => {
    setAssignedResAry([{}]);
    setNotAssignedResObj(userList);
  };

  const handleSearchInputChange = (res_email) => {
    setResKwargByEmail(res_email);
    filterResObj(res_email);
  };

  const filterResObj = (res_email) => {
    if (res_email === null) {
      setSearchedUserIndex(-1);
    } else {
      notAssignedResObj.map((user, index) => {
        if (user.email === res_email) setSearchedUserIndex(index);
      });
    }
  };

  const fetchAssignedResAry = async () => {
    try {
      const res = await axiosClient.get(`projectUsers/assigned/${project.id}/`);
      console.log("Get ProjectUserAry:", res);
      setAssignedResAry(res.data.res_array);
    } catch (err) {
      console.log("Err getting ProjectUserAry:", err);
    }
  };

  const newAssign = async (newAssignArray) => {
    try {
      const res = await axiosClient.post(
        `projectUsers/newAssign/${project.id}/`,
        {
          res_array: newAssignArray,
        }
      );
      console.log("Post new Assign ary:", res);
      // todo: 案件編集をロックするAPIを叩く予定
    } catch (err) {
      console.log("Err posting new Assign ary:", err);
    }
  };

  const deleteAssign = async (deleteAssignArray) => {
    console.log(deleteAssignArray);
    try {
      const res = await axiosClient.post(
        `projectUsers/deleteAssign/${project.id}/`,
        {
          res_array: deleteAssignArray,
        }
      );
      console.log("Post delete Assign ary:", res);
    } catch (err) {
      console.log("Err posting delete Assign ary:", err);
    }
  };

  const saveResAssign = () => {
    const postResArray = assignedResObj.map((user) => {
      console.log(user.id);
      return user.id;
    });
    console.log(postResArray);
    const newAssignArray = postResArray.filter((userIndex) => {
      if (!assignedResAry.includes(userIndex)) return userIndex;
    });
    const deleteAssignArray = assignedResAry.filter((userIndex) => {
      if (!postResArray.includes(userIndex)) return userIndex;
    });
    console.log(newAssignArray);
    console.log(deleteAssignArray);
    newAssignArray.length !== 0 && newAssign(newAssignArray);
    if (deleteAssignArray.length !== 0) {
      deleteAssign(deleteAssignArray);
    }
  };

  useEffect(() => {
    fetchAssignedResAry();
  }, [project]);

  useEffect(() => {
    createEmailList(notAssignedResObj);
    createUserListForLabel(notAssignedResObj);
  }, [notAssignedResObj]);

  const createEmailList = (userlist) => {
    const filteredUserList = userlist.map((user) => {
      return user.email;
    });
    setEmailListByUser(filteredUserList);
  };

  const createUserListForLabel = (userlist) => {};

  useEffect(() => {
    const userList__filtered = userList.filter((user) => {
      if (assignedResAry.includes(user.id)) {
        return user;
      }
    });
    const userList__outfiltered = userList.filter((user) => {
      if (!assignedResAry.includes(user.id)) {
        return user;
      }
    });
    setAssignedResObj(userList__filtered);
    setNotAssignedResObj(userList__outfiltered);
  }, [assignedResAry]);

  return (
    <>
      <Dialog open={resAssignModalOpen} onClose={handleClose}>
        <DialogTitle
          sx={{
            backgroundColor: "#f5f5f5",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>RESアサイン割当</Box>
          <Button
            onClick={() => saveResAssign()}
            variant="contained"
            color="primary"
            size="large"
            sx={{
              marginRight: "16px",
            }}
          >
            保存
          </Button>
        </DialogTitle>
        <DialogContent className="res_modal__content">
          <Box
            sx={{
              mt: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <DialogContentText>案件名：{project.name}</DialogContentText>
            <Box>
              <Button
                onClick={() => handleApeendAll()}
                variant="outlined"
                color="success"
                sx={{
                  marginRight: "16px",
                }}
              >
                追加ALL
              </Button>
              <Button
                variant="outlined"
                color="warning"
                onClick={() => handleDeleteAll()}
              >
                削除ALL
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              height: "420px",
            }}
          >
            <Box className="moda_hedding">
              <Typography variant="h6">アサイン済みユーザー</Typography>
            </Box>
            <Table>
              <TableBody>
                {assignedResObj.map((user) => {
                  return (
                    <TableRow>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>
                        <Button onClick={() => handleDelete(user)}>削除</Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Box className="moda_hedding">
              <Typography variant="h6">ユーザー一覧</Typography>
              <Box>
                <Autocomplete
                  disablePortal
                  options={emailListByUser}
                  onChange={(e, newValue) => handleSearchInputChange(newValue)}
                  value={resKwargByEmail}
                  noOptionsText={"全てのユーザーがアサイン済みです"}
                  renderInput={(param) => (
                    <TextField {...param} label="メアド検索" />
                  )}
                  size="small"
                  sx={{
                    width: "220px",
                    marginRight: "40px",
                  }}
                />
              </Box>
            </Box>
            <Table>
              <TableBody>
                {searchedUserIndex === -1 ? (
                  notAssignedResObj.map((user) => {
                    return (
                      <TableRow>
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>
                          <Button onClick={() => handleAppend(user)}>
                            追加
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell>
                      {notAssignedResObj[searchedUserIndex].name}
                    </TableCell>
                    <TableCell>
                      {notAssignedResObj[searchedUserIndex].email}
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() =>
                          handleAppend(notAssignedResObj[searchedUserIndex])
                        }
                      >
                        追加
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ResAssignModal;
