import { AppBar, Button, Toolbar, Typography } from "@mui/material";
import { USER_LIST } from "../constants/constants";

const Header = ({ user_name, user_id, isLoggined, logout }) => {
  return (
    <AppBar position="static" component="nav">
      <Toolbar>
        <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
          KINACO
        </Typography>
        {isLoggined && (
          <>
          <Typography mr={1}>{USER_LIST[user_id]}さん</Typography>
          <Typography mr={1}>ID: {user_id}</Typography>
          <Button color="inherit" onClick={() => logout()}>
          LOGOUT
          </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
