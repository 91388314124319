import { Box, Button, Container, Typography } from "@mui/material";
import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import { ASSIGN_MAX_COUNT, BASEURL } from "../../constants/constants";
import Article from "./Article";
import InputPlain from "./InputPlain";
import IntEvi from "./IntEvi";
import Loading from "./Loading";
import RadioModule from "./RadioModule";
import StrEvi from "./StrEvi";

export const GetServerDataContext = createContext({
  propositions: [],
  sources: [],
});

export const StatusContext = createContext();
export const SetStatusContext = createContext();

const ProjectDetailComponent = ({ user_id, isLoggined }) => {
  const params = useParams();
  const [sourceAry, setSourceAry] = useState([]);
  const [currentSourceID, setCurrentSourceID] = useState(0);
  const [sourceIndex, setSourceIndex] = useState(0);
  const [isGettingPropositions, setIsGettingPropositions] = useState(false);
  const [sources, setSources] = useState([
    {
      id: 0,
      project_id: 0,
      name: "",
      url: "",
      memo: "",
      number: 0,
    },
  ]);
  const [propositions, setPropositions] = useState([
    {
      id: 0,
      project_id: 0,
      module_type: 0,
      title: "",
      flag: false,
      section_num: 0,
    },
  ]);
  const [sectionsWithPropositions, setSectionsWithPropositions] = useState([
    {
      description: "",
      id: 0,
      number: 0,
      project_id: 0,
      proposition_section: [
        {
          id: 0,
          module_type: 0,
          title: "",
          flag: false,
          description: null,
          number: 0,
          section_id: 0,
        },
      ],
    },
  ]);
  const [startConfirm, setStartConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(false);
  const [sourceUsersID, setSourceUsersID] = useState(0);
  const [isFinished, setIsFinished] = useState(false);
  const [children, setChildren] = useState({
    ChildInt: [{}],
    ChildText: [{}],
  });
  const [cookie, setCookie, removeCookie] = useCookies(["token"]);
  const axiosClient = axios.create({
    baseURL: BASEURL,
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + cookie.token.accessToken,
    },
  });

  const proceedToNextSource = () => {
    fetchSourceAry();
    setIsLoading(false);
    setProgress(false);
    setStartConfirm(false);
    setTimeout(() => {
      setIsLoading(true);
    }, 1000);
  };

  const StatusProvider = ({ children }) => {
    const [status, setStatus] = useState({
      answerStr: {},
      answerInt: {},
      Err: {},
    });

    const handleSaveAnswer = () => {
      const validated = validateAnswer(sources[sourceIndex].id); // boolean
      if (validated) {
        console.log("its success");
        saveAnswer(sources[sourceIndex].id);
        putFlagSourceUsers();

        proceedToNextSource();
      } else {
        console.log("its err");
      }
    };

    const validateAnswer = (source_id) => {
      let error = {};
      let validated = true;

      sectionsWithPropositions.map((section) => {
        section.proposition_section.map((proposition) => {
          if (proposition.module_type === 2) {
            if (status.answerStr[proposition.id] === undefined) {
              validated = false;
              error = {
                ...error,
                [proposition.id]: {
                  ...error[proposition.id],
                  value: {
                    message: "回答が空欄です。",
                  },
                },
              };
            }
          }
        });
      });

      for (let key in status.answerStr) {
        if (
          status.answerStr[key].value === undefined ||
          status.answerStr[key].value === ""
        ) {
          validated = false;
          error = {
            ...error,
            [key]: {
              ...error[key],
              value: {
                message: "回答が空欄です。",
              },
            },
          };
        } else {
          error = {
            ...error,
            [key]: { ...error[key] },
          };
        }
      }
      console.log(error);

      for (let key in status.answerInt) {
        if (
          status.answerInt[key].value === 2 ||
          status.answerInt[key].value === 3
        ) {
          if (
            status.answerInt[key].evidence === undefined ||
            status.answerInt[key].evidence === ""
          ) {
            validated = false;
            error = {
              ...error,
              [key]: {
                ...error[key],
                evidence: {
                  message:
                    "YES、またはYESかもを選択した場合は申送を入力してください。",
                },
              },
            };
          }
        }
        if (
          status.answerInt[key].value === 1 ||
          status.answerInt[key].value === 2
        ) {
          if (
            status.answerInt[key].message === undefined ||
            status.answerInt[key].message === ""
          ) {
            validated = false;
            console.log("before Yeskamo", error);
            error = {
              ...error,
              [key]: {
                ...error[key],
                message: {
                  message:
                    "YESかも、またはNOかもを選択した場合は申送を入力してください。",
                },
              },
            };
          }
        }
      }

      console.log(error);
      setStatus({
        ...status,
        Err: error,
      });
      return validated;
    };

    const saveAnswer = (source_id) => {
      console.log("save the Answer", status.answerInt, status.answerStr);

      // todo: KINGさんを待ってから保存成功アラートを実装する
      for (let key in status.answerStr) {
        // console.log(status.answerStr[key]);
        const result = {
          res_id: cookie.user_id,
          proposition_id: Number(key),
          source_id: source_id,
          value: status.answerStr[key].value,
          evidence: status.answerStr[key].evidence,
          message: status.answerStr[key].message,
        };
        let isSetChildFlag = false;
        let childTextID = null;

        // console.log(status.answerStr[key].evidence)

        const postChildText = () => {
          axiosClient
            .get(BASEURL + "/childText/")
            .then((children) => {
              children.data.map((child) => {
                if (
                  child.sources_id === source_id &&
                  child.propositions_id === Number(key)
                ) {
                  childTextID = child.id;
                  isSetChildFlag = true;
                }
              });
            })
            .catch((err) => {
              console.log("Getting childtext is Err: ", err);
            });
          if (isSetChildFlag) {
            axiosClient
              .put(`${BASEURL}/childText/${childTextID}/`, result)
              .then((res) => {
                console.log("Putting childText is success: ", res);
                console.log("data", res.data);
              })
              .catch((err) => {
                console.log("Putting childText is Err: ", err);
              });
          } else {
            axiosClient
              .post(BASEURL + "/childText/", result)
              .then((res) => {
                console.log("Posting childText is success: ", res);
                console.log("data", res.data);
              })
              .catch((err) => {
                console.log("Posting childText is Err: ", err);
              });
          }
        };
        postChildText();

        console.log("ChildText result:", result);
      }
      for (let key in status.answerInt) {
        const result = {
          res_id: cookie.user_id,
          proposition_id: Number(key),
          source_id: source_id,
          value: status.answerInt[key].value,
          evidence: status.answerInt[key].evidence,
          message: status.answerInt[key].message,
        };
        let isSetChildFlag = false;
        let childTextID = null;

        if (status.answerInt[key].value === undefined) {
          result.value = 0;
          setStatus({
            ...status,
            answerInt: {
              ...status.answerInt,
              [key]: {
                ...status.answerInt[key],
                value: 0,
              },
            },
          });
        }
        // console.log(status.answerStr[key].evidence);

        const postChildInt = () => {
          axiosClient
            .get(BASEURL + "/childInt/")
            .then((children) => {
              children.data.map((child) => {
                if (
                  child.sources_id === source_id &&
                  child.propositions_id === Number(key)
                ) {
                  childTextID = child.id;
                  isSetChildFlag = true;
                }
              });
            })
            .catch((err) => {
              console.log("Getting childInt is Err: ", err);
            });
          if (isSetChildFlag) {
            axiosClient
              .put(`${BASEURL}/childInt/${childTextID}/`, result)
              .then((res) => {
                console.log("Putting childInt is success: ", res);
                console.log("data", res.data);
              })
              .catch((err) => {
                console.log("Putting childInt is Err: ", err);
              });
          } else {
            axiosClient
              .post(BASEURL + "/childInt/", result)
              .then((res) => {
                console.log("Posting childInt is success: ", res);
                console.log("data", res.data);
              })
              .catch((err) => {
                console.log("Posting childInt is Err: ", err);
              });
          }
        };
        postChildInt();

        console.log("ChildInt result:", result);
      }

      console.log("children save!!!");
    };

    return (
      <StatusContext.Provider value={status}>
        <SetStatusContext.Provider value={setStatus}>
          {children}
          <Box className="status_buttons">
            <Button
              className="status_buttons__inner"
              variant="contained"
              onClick={() => handleSaveAnswer()}
            >
              <Typography variant="subtitle">保存</Typography>
            </Button>
          </Box>
        </SetStatusContext.Provider>
      </StatusContext.Provider>
    );
  };

  const getRefresh = () => {
    axios
      .post(BASEURL + "/token/refresh/", { refresh: cookie.token.refreshToken })
      .then((res) => {
        console.log("Refresh is success:", res);
        console.log(cookie.token);
        const token = {
          accessToken: res.data.access,
          refreshToken: res.data.refresh,
        };
        setCookie("token", token, { path: "/" });
      })
      .catch((err) => {
        console.log("Refresh is err: ");
        console.log(err);
        removeCookie("token");
      });
  };

  axiosClient.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      if (err.response.status === 401) {
        getRefresh();
      } else {
        return err;
      }
    }
  );

  const fetchChildren = (source_id) => {
    console.log("FETCH CHILDREN");
    console.log("SOURCE_ID:", source_id);
    source_id &&
      axiosClient
        .get(`/get_children/${source_id}`)
        .then((res) => {
          // console.log("Children:", res.data);
          setChildren(res.data);
        })
        .catch((err) => {
          console.log("Get chidlren Err:", err);
        });
  };

  const fetchSections = () => {
    axiosClient
      .get(`/get_sections_with_propositions/${params.id}`)
      .then((res) => {
        console.log("Get proposition with section:", res);
        setSectionsWithPropositions(res.data);
        setIsGettingPropositions(true);
      })
      .catch((err) => {
        console.log("Err proposition with section:", err);
      });
  };

  const fetchSources = () => {
    axiosClient
      .get(`/sources/${params.id}`)
      .then((res) => {
        const result = [{ id: null }, ...res.data];
        console.log("Sources:", result);
        setSources(result);
        fetchChildren(res.data[sourceIndex].id);
      })
      .catch((err) => {
        console.log("Err getting sources : ", err);
      });
  };

  const fetchFirstSourceAry = () => {
    axiosClient
      .get(
        `/sources/index_ary/${params.id}/${cookie.user_id}/${ASSIGN_MAX_COUNT}`
      )
      .then((res) => {
        console.log("SourcesAry:", res.data);
        const new_source_users_id = res.data.source_users_id;
        console.log("Get first_source_index_list:", res.data.source_users_id);
        if (res.data.index_array.length === 0) {
          console.log("Source Ary is all clean");
          setIsFinished(true);
        }
        const resultAry = res.data.index_array;

        setSourceAry(resultAry);
        setCurrentSourceID(resultAry[0]);

        new_source_users_id.length !== 0 &&
          setSourceUsersID(new_source_users_id[0]);
      })
      .catch((err) => {
        console.log("Get sources_ary Err: ", err);
      });
  };

  const fetchSourceAry = () => {
    axiosClient
      .post(`/sources/index_ary/`, {
        assign_max_count: ASSIGN_MAX_COUNT,
        index_array: sourceAry,
        res_id: cookie.user_id,
      })
      .then((res) => {
        console.log("New SourcesAry:", res);

        const resultAry = res.data.index_array;

        setSourceAry(resultAry);
        setCurrentSourceID(resultAry[0]);
        if (res.data.index_array.length === 0) {
          console.log("Source Ary is all clean");
          setIsFinished(true);
        }
      })
      .catch((err) => {
        console.log("Get new sources_ary Err: ", err);
      });
  };

  const putFlagSourceUsers = () => {
    axiosClient
      .put(`/source_users/${sourceUsersID}/`, {
        res_id: cookie.user_id,
        source_id: currentSourceID,
        flag: true,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const putAssignCount = () => {
    axiosClient
      .put(`/sources/assign/${currentSourceID}/`, {
        assign_max_count: ASSIGN_MAX_COUNT,
      })
      .then((res) => {
        if (res.response.status === 406) {
          console.log("assign MAX over");
          assignOverCount();
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const assignOverCount = () => {
    window.alert(
      "このソースは既に既定の回答数を達成しています。次のソースへと進んでください。"
    );

    proceedToNextSource();
  };

  const postSourceUsers = () => {
    axiosClient
      .post(`/source_users/`, {
        source_id: sourceIndex,
        res_id: cookie.user_id,
      })
      .then((res) => {
        console.log("Pushed source users:", res);
        setSourceUsersID(res.data.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleConfirm = () => {
    console.log("clicked start confirm button");
    setStartConfirm(true);

    // ソースユーザーIDが初期値以外（取得していたら）アサインカウント追加とソースユーザーをインサートしない
    if (sourceUsersID === 0) {
      putAssignCount();
      postSourceUsers();
    }
  };

  const setTimeoutProgress = (time) => {
    setTimeout(() => {
      setProgress(true);
    }, time);
  };

  useEffect(() => {
    fetchSources();
    fetchSections();
    fetchFirstSourceAry();

    !isLoading && setTimeoutProgress(500);
  }, []);

  // loading
  useEffect(() => {
    progress && setIsLoading(true);
  }, [sourceAry, progress]);

  // 　新しいソースIDに変更された時に、インデックスを変更する
  useEffect(() => {
    const currentSource = sources
      .map((source, index) => {
        if (source.id === currentSourceID) {
          return index;
        } else {
          return null;
        }
      })
      .filter((source) => source);
    console.log("currentSource:", currentSource);
    setSourceIndex(currentSource[0]);
    console.log("SOURCE_ARY is Changed:", currentSource);
  }, [currentSourceID]);

  useEffect(() => {
    sources[sourceIndex] &&
      sources[sourceIndex].id &&
      saveSourceIndex(sourceIndex);
  }, [sourceIndex]);

  const saveSourceIndex = (index) => {
    let confirm = true;
    // if (!sources[sourceIndex].Flag) {
    //   confirm = window.confirm(
    //     "回答を保存していません、ページから離れてもよろしいですか？"
    //   );
    // }

    console.log("save source", sources[index]);
    if (sources[index].id && confirm) {
      fetchChildren(sources[index].id);
    }
  };

  const AnswerModuleComponent = ({ module_type, proposition, source_id }) => {
    if (module_type === 1) {
      return (
        <>
          <RadioModule proposition={proposition} />
          <IntEvi proposition={proposition} />
        </>
      );
    } else if (module_type === 2) {
      return (
        <>
          <InputPlain proposition={proposition} />
          <StrEvi proposition={proposition} />
        </>
      );
    }
  };

  const openerChange = (index) => {
    console.log("params.id", params.id);
    if (params.id) {
      console.log("open article in opnenner window");
      window.opener.open(sources[index].url);
    }
  };

  const handleFinish = () => {
    window.close();
  };

  return (
    <Container component="detail" maxWidth="md" className="res_projectDetail">
      <GetServerDataContext.Provider
        value={{
          propositions: propositions,
          sources: sources,
          source_index: sourceIndex,
          children: children,
        }}
      >
        {!startConfirm ? (
          <Loading
            title={sources[sourceIndex] && sources[sourceIndex].name}
            isLoading={isLoading}
            isFinished={isFinished}
            handleConfirm={() => handleConfirm}
            handleFinish={() => handleFinish}
          />
        ) : (
          <StatusProvider>
            <Box className="title_block">
              <Box className="title_inner" sx={{}}>
                {sources[sourceIndex] && (
                  <Typography className="title">
                    {sources[sourceIndex].number}. {sources[sourceIndex].name}
                  </Typography>
                )}
              </Box>
              <Article openerChange={(i) => openerChange(i)} />
            </Box>
            {
              <>
                {sectionsWithPropositions.map((section, index) => (
                  <Box className="section__container" key={section.id}>
                    <Typography className="title">
                      No.{section.number}：{section.title}
                    </Typography>
                    {section.description && (
                      <Typography className="desc">
                        {section.description}
                      </Typography>
                    )}
                    <Box className="section__inner">
                      {section.proposition_section.map(
                        (proposition, prop_index) => (
                          <Box className="question__block" key={proposition.id}>
                            <Typography className="prop-title">
                              {proposition.title}
                            </Typography>
                            {proposition.description && (
                              <Typography className="prop-description">
                                ※{proposition.description}
                              </Typography>
                            )}
                            <AnswerModuleComponent
                              module_type={proposition.module_type}
                              proposition={proposition}
                              source_id={sources[sourceIndex].id}
                            />
                          </Box>
                        )
                      )}
                    </Box>
                  </Box>
                ))}
              </>
            }
          </StatusProvider>
        )}
      </GetServerDataContext.Provider>
    </Container>
  );
};

export default ProjectDetailComponent;
